var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      attrs: { "content-class": "request-success-popup" },
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c("div", [
        _c("div", { staticClass: "request-success-popup__content" }, [
          _c("img", {
            staticClass: "request-success-popup-chackmark",
            attrs: {
              loading: "lazy",
              src: require("@/assets/img/checkmark.svg?inline"),
              alt: "bg",
            },
          }),
          _c("div", { staticClass: "request-success-popup__desc" }, [
            _vm._v(" " + _vm._s(_vm.textMsg) + " "),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "request-success-popup__buttons" },
          [
            _c("btn", {
              staticClass: "request-success-popup__buttons-item",
              attrs: { type: "mint-dark", text: "OK" },
              on: { click: _vm.close },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }