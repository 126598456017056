// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".get-booster-popup {\n  width: 100%;\n  max-width: 645px;\n  flex-direction: column;\n  background-color: #C6CCD7 !important;\n  padding: 2em 3em !important;\n  border-radius: 15px;\n}\n.get-booster-popup .popup__close-btn {\n  background-color: transparent;\n}\n.get-booster-popup .popup__close-btn .close-btn__element {\n  background-color: black;\n  height: 6px;\n  width: 25px;\n}\n.get-booster-popup__text {\n  margin-top: 20px;\n  text-transform: initial;\n}\n.get-booster-popup-button {\n  min-width: 217px;\n}\n.get-booster-popup .btns-boosters-text {\n  font: 400 24px \"Tektur\";\n}\n.get-booster-popup .btns-boosters-text--subtext {\n  text-transform: lowercase;\n  font-size: 14px;\n  color: #919EB7;\n}\n.get-booster-popup__content {\n  text-transform: uppercase;\n  text-align: center;\n}\n.get-booster-popup__title {\n  text-align: center;\n  text-transform: uppercase;\n  font: 800 48px Raleway;\n}\n.get-booster-popup__desc {\n  display: flex;\n  text-transform: initial;\n  margin: 22px 0 30px;\n  justify-content: center;\n  gap: 50px;\n}\n.get-booster-popup__booster {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n.get-booster-popup__buttons {\n  display: flex;\n  gap: 40px;\n  justify-content: center;\n}\n.get-booster-popup__buttons-item {\n  width: 50%;\n}\n@media screen and (max-width: 640px) {\n.get-booster-popup__title {\n    font-size: 24px;\n}\n.get-booster-popup__text {\n    font-size: 12px;\n}\n.get-booster-popup__buttons {\n    gap: 10px;\n    flex-direction: column;\n}\n.get-booster-popup__buttons-item {\n    width: 100%;\n}\n.get-booster-popup__desc {\n    flex-direction: column;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
