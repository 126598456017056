var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      attrs: { "content-class": "booster-popup" },
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c("div", { staticClass: "booster-popup__content" }, [
        _c("div", { staticClass: "booster-popup__title" }, [
          _vm._v("Activate Booster"),
        ]),
        _c("div", { staticClass: "booster-popup__text" }, [
          _vm._v(
            " Here are all your boosters and their time until the end. Once activated, you won't be able to remove it manually. You can apply a new one only after the completion of the previous one. "
          ),
        ]),
        _c(
          "div",
          { staticClass: "booster-popup__radios" },
          [
            _vm.isLoading
              ? _c("Loader", { attrs: { xs: "" } })
              : _vm._l(_vm.filteredBoosters, function (booster) {
                  return _c(
                    "div",
                    {
                      key: booster.id,
                      staticClass: "booster-popup__radios-item",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "On " + _vm._s(_vm.convertToTime(booster.expired_at))
                        ),
                      ]),
                      _c("radio", {
                        attrs: { val: String(booster.id) },
                        model: {
                          value: _vm.selectedBooster,
                          callback: function ($$v) {
                            _vm.selectedBooster = $$v
                          },
                          expression: "selectedBooster",
                        },
                      }),
                    ],
                    1
                  )
                }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "booster-popup__buttons" },
        [
          _c("btn", {
            staticClass: "booster-popup__buttons-item",
            attrs: { type: "bg-white", text: "Cancel" },
            on: { click: _vm.close },
          }),
          _c("btn", {
            staticClass: "booster-popup__buttons-item",
            attrs: { type: "icon-gradient", text: "Apply" },
            on: { click: _vm.activate },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }