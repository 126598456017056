<template>
    <popup v-model="popup" @input="close" content-class="get-booster-popup">
        <div class="get-booster-popup__content">
            <div class="get-booster-popup__title">Get EXP booster</div>

            <div class="get-booster-popup__text">
                Buy your booster with discount! You can buy with $MF tokens or stake your $MF tokens in order to activate the EXP boost. 
                After staking, your $MF tokens can be returned to your wallet. 
                A booster can only be activated for one NFT Fighter and has a expire date.
            </div>

            <div class="get-booster-popup__desc">
                <div v-for="booster in boosters" :key="booster.id" class="get-booster-popup__booster">
                    <div>
                        <p class="btns-boosters-text">{{`${booster.name} BOOSTER`}}</p>
                        <p class="btns-boosters-text btns-boosters-text--subtext">STAKE ON {{toDays(booster.duration)}} DAYS</p>
                    </div>
                    <btn
                        :type="'mint-dark'"
                        :text="`STAKE ${toLocaleFormat(booster.stakingPrice || 0)} MF`"
                        :hover-text="`STAKE ${toLocaleFormat(booster.stakingUsdPrice || 0)} $`"
                        class="get-booster-popup-button"
                        @click="stakeHandler(booster)"
                    />

                    <btn
                        :type="'mint-dark'"
                        :text="`BUY ${toLocaleFormat(booster.price || 0)} MF`"
                        :hover-text="`BUY ${toLocaleFormat(booster.usd_price || 0)} $`"
                        class="get-booster-popup-button"
                        @click="buyHandler(booster)"
                    />
                </div>
            </div>
        </div>

        <div class="get-booster-popup__buttons">
            <btn
                class="get-booster-popup__buttons-item"
                type="bg-white"
                text="Cancel"
                @click="close"
            />
        </div>
    </popup>
</template>

<script>
import BoostersMixin from "@/mixins/boostersMixin";

export default {
    name: "GetBoostersPopup",
    mixins: [BoostersMixin],
    props: {
    },
    data() {
        return {
            popup: false,
        }
    },
    methods: {
        open(){
            this.popup = true
        },
        close() {
            this.$emit('close')
            this.popup = false
        },
        activate() {
            this.popup = false;

        },
        stakeHandler(booster) {
            this.$emit('stakeBooster', booster.id);
            this.close();
        },
        buyHandler(booster) {
            this.$emit('buyBoosterHandler', booster.id);
            this.close();
        }
    }
}
</script>

<style lang="scss">
.get-booster-popup {
    width: 100%;
    max-width: 645px;
    flex-direction: column;
    background-color: #C6CCD7!important;
    padding: 2em 3em!important;
    border-radius: 15px;

    .popup__close-btn {
        background-color: transparent;

        .close-btn__element {
            background-color: black;
            height: 6px;
            width: 25px;
        }
    }

    &__text {
        margin-top: 20px;
        text-transform: initial;
    }
    &-button {
        min-width: 217px;
    }

    .btns-boosters-text {
        font: 400 24px "Tektur";

        &--subtext {
            text-transform: lowercase;
            font-size: 14px;
            color: #919EB7;
        }
    }
}
.get-booster-popup__content {
    text-transform: uppercase;
    text-align: center;
}
.get-booster-popup__title {
    text-align: center;
    text-transform: uppercase;
    font: 800 48px Raleway;
}
.get-booster-popup__desc {
    display: flex;
    text-transform: initial;
    margin: 22px 0 30px;
    justify-content: center;
    gap: 50px;


}
.get-booster-popup__booster {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.get-booster-popup__buttons {
    display: flex;
    gap: 40px;
    justify-content: center;

    &-item {
        width: 50%;
    }
}

@media screen and (max-width: $bp_mb) {
    .get-booster-popup__title {
        font-size: 24px;
    }

    .get-booster-popup__text {
        font-size: 12px;
    }

    .get-booster-popup__buttons {
        gap: 10px;
        flex-direction: column;

        &-item {
            width: 100%;
        }
    }

    .get-booster-popup__desc {
        flex-direction: column;
    }
}
</style>
