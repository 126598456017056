var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: ["booster", { active: _vm.active }],
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.active
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "popover",
                  rawName: "v-popover.bottom",
                  value: { name: _vm.text },
                  expression: "{name: text}",
                  modifiers: { bottom: true },
                },
              ],
              staticClass: "booster-rate",
            },
            [_vm._v(" " + _vm._s(_vm.text) + " ")]
          )
        : _c("span", { staticClass: "booster__text" }, [
            _vm._v(_vm._s(_vm.emptyText)),
          ]),
      _vm.duration
        ? _c(
            "portal",
            { attrs: { to: "main-popup" } },
            [
              _c(
                "popover",
                {
                  staticClass: "booster__popover",
                  attrs: { name: _vm.text, width: 290, event: "hover" },
                },
                [
                  _c("div", { staticClass: "passive" }, [
                    _c("div", { staticClass: "booster__popover-content" }, [
                      _c("h2", { staticClass: "booster__popover-title" }, [
                        _vm._v("Booster " + _vm._s(_vm.text)),
                      ]),
                      _c("div", { staticClass: "booster__popover-text" }, [
                        _vm._v(" remaining time: "),
                      ]),
                      _c("div", { staticClass: "booster__popover-timer" }, [
                        _c(
                          "div",
                          { staticClass: "booster__popover-timer" },
                          [
                            _c("staking-timer", {
                              attrs: { time: _vm.duration },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }