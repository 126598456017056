<template>
    <div
        :class="{'active-booster--active': !blockTime}"
        class="active-booster"
    >
        <div class="active-booster-text">{{text}}</div>

        <btn
            v-if="!blockTime"
            type="mint-dark"
            class="active-booster__item"
            :text="`Activate (${count})`"
            @click="$emit('click')"
        />

        <vue-countdown
            v-else
            :time="+blockTime * 1000"
            :transform="transform"
            v-slot="{days, hours, minutes}"
            @end="$emit('end')"
        >
            <div class="active-booster-time">
                <div class="active-booster__item">
                    <div class="active-booster__number">{{ days }}</div>
                </div>

                <div class="active-booster-separator">:</div>

                <div class="active-booster__item">
                    <div class="active-booster__number">{{ hours }}</div>
                </div>

                <div class="active-booster-separator">:</div>

                <div class="active-booster__item">
                    <div class="active-booster__number">{{ minutes }}</div>
                </div>
            </div>
        </vue-countdown>
    </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
    name: "ActiveBooster",
    components: {
        VueCountdown,
    },
    props: {
        blockTime: { type: [String, Number], default: '' },
        text: { type: String, default: '' },
        count: { type: Number, default: 0 },
    },
    methods: {
        transform(props) {
            const formattedProps = {};

            Object.entries(props).forEach(([key, value]) => {
              formattedProps[key] = value < 10 ? `0${value}` : String(value);
            });

            return formattedProps;
        },
    }
}
</script>

<style lang="scss" scoped>

.active-booster {
  display: flex;
  align-items: center;
  background: #6B7B98;
  margin: auto;

  &-time {
      justify-content: center;
      align-items: center;
      display: flex;
      font: 500 16px "Tektur";
      padding: 0 20px;
  }
  &-text {
      font: 500 20px "Tektur";
      padding: 10px;
      background-color: #919EB7;
      color: #6B7B98;
  }
}

.active-booster--active {
    background: initial;
    .active-booster__item {
        margin: initial;
        max-height: 45px;
    }
    .active-booster-text {
        font-weight: 600;
        color: black;
        background: linear-gradient(45deg, #FF9509 0%, #FFCC13 100%);
    }
}

.active-booster__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
}

.active-booster-separator {
    height: 24px;
    animation: blink 2s infinite;
}

@keyframes blink{
    0% {
        color: white;
    }
    50% {
        color: #7a88a3;
    }
}

@-webkit-keyframes blink{
    0% {
        color: white;
    }
    50% {
        color: #7a88a3;
    }
}
</style>
