var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "active-booster",
      class: { "active-booster--active": !_vm.blockTime },
    },
    [
      _c("div", { staticClass: "active-booster-text" }, [
        _vm._v(_vm._s(_vm.text)),
      ]),
      !_vm.blockTime
        ? _c("btn", {
            staticClass: "active-booster__item",
            attrs: { type: "mint-dark", text: `Activate (${_vm.count})` },
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
            },
          })
        : _c("vue-countdown", {
            attrs: { time: +_vm.blockTime * 1000, transform: _vm.transform },
            on: {
              end: function ($event) {
                return _vm.$emit("end")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ days, hours, minutes }) {
                  return [
                    _c("div", { staticClass: "active-booster-time" }, [
                      _c("div", { staticClass: "active-booster__item" }, [
                        _c("div", { staticClass: "active-booster__number" }, [
                          _vm._v(_vm._s(days)),
                        ]),
                      ]),
                      _c("div", { staticClass: "active-booster-separator" }, [
                        _vm._v(":"),
                      ]),
                      _c("div", { staticClass: "active-booster__item" }, [
                        _c("div", { staticClass: "active-booster__number" }, [
                          _vm._v(_vm._s(hours)),
                        ]),
                      ]),
                      _c("div", { staticClass: "active-booster-separator" }, [
                        _vm._v(":"),
                      ]),
                      _c("div", { staticClass: "active-booster__item" }, [
                        _c("div", { staticClass: "active-booster__number" }, [
                          _vm._v(_vm._s(minutes)),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }