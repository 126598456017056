var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      attrs: { "content-class": "get-booster-popup" },
      on: { input: _vm.close },
      model: {
        value: _vm.popup,
        callback: function ($$v) {
          _vm.popup = $$v
        },
        expression: "popup",
      },
    },
    [
      _c("div", { staticClass: "get-booster-popup__content" }, [
        _c("div", { staticClass: "get-booster-popup__title" }, [
          _vm._v("Get EXP booster"),
        ]),
        _c("div", { staticClass: "get-booster-popup__text" }, [
          _vm._v(
            " Buy your booster with discount! You can buy with $MF tokens or stake your $MF tokens in order to activate the EXP boost. After staking, your $MF tokens can be returned to your wallet. A booster can only be activated for one NFT Fighter and has a expire date. "
          ),
        ]),
        _c(
          "div",
          { staticClass: "get-booster-popup__desc" },
          _vm._l(_vm.boosters, function (booster) {
            return _c(
              "div",
              { key: booster.id, staticClass: "get-booster-popup__booster" },
              [
                _c("div", [
                  _c("p", { staticClass: "btns-boosters-text" }, [
                    _vm._v(_vm._s(`${booster.name} BOOSTER`)),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "btns-boosters-text btns-boosters-text--subtext",
                    },
                    [
                      _vm._v(
                        "STAKE ON " +
                          _vm._s(_vm.toDays(booster.duration)) +
                          " DAYS"
                      ),
                    ]
                  ),
                ]),
                _c("btn", {
                  staticClass: "get-booster-popup-button",
                  attrs: {
                    type: "mint-dark",
                    text: `STAKE ${_vm.toLocaleFormat(
                      booster.stakingPrice || 0
                    )} MF`,
                    "hover-text": `STAKE ${_vm.toLocaleFormat(
                      booster.stakingUsdPrice || 0
                    )} $`,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.stakeHandler(booster)
                    },
                  },
                }),
                _c("btn", {
                  staticClass: "get-booster-popup-button",
                  attrs: {
                    type: "mint-dark",
                    text: `BUY ${_vm.toLocaleFormat(booster.price || 0)} MF`,
                    "hover-text": `BUY ${_vm.toLocaleFormat(
                      booster.usd_price || 0
                    )} $`,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.buyHandler(booster)
                    },
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "get-booster-popup__buttons" },
        [
          _c("btn", {
            staticClass: "get-booster-popup__buttons-item",
            attrs: { type: "bg-white", text: "Cancel" },
            on: { click: _vm.close },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }