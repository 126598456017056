var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ContentCharacter",
    { staticClass: "market-place" },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isLoadingData
            ? _c("Loader")
            : _c(
                "div",
                { staticClass: "my-nft" },
                [
                  _c("market-place-header", {
                    attrs: { urls: _vm.getPathUrls },
                  }),
                  _c("div", { staticClass: "my-nft__content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-nft__content-box my-nft__content-box--card",
                        attrs: {
                          "data-aos": "fade-right",
                          "data-aos-delay": "300",
                        },
                      },
                      [
                        _c("card-wrapper", {
                          staticClass: "my-nft__avatar",
                          attrs: {
                            image: _vm.GET_MY_CHARACTER.mf_attributes.image,
                            "web-images":
                              _vm.GET_MY_CHARACTER.mf_attributes.web_images,
                            type: _vm.GET_MY_CHARACTER.mf_attributes.type,
                            tier: _vm.GET_MY_CHARACTER.mf_attributes.tier,
                            statistic: _vm.statistic,
                            isBackground: false,
                            "image-center": "",
                            fullSize: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("click")
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-nft__content-box my-nft__content-box--main",
                        attrs: {
                          "data-aos": "fade-right",
                          "data-aos-delay": "300",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "my-nft__info" },
                          [
                            _c("h2", { staticClass: "my-nft__info-title" }, [
                              _vm._v(_vm._s(_vm.GET_MY_CHARACTER.name)),
                            ]),
                            _vm.GET_IS_MOBILE
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "popover",
                                        rawName: "v-popover.bottom",
                                        value: { name: "fighter-video" },
                                        expression: "{ name: 'fighter-video' }",
                                        modifiers: { bottom: true },
                                      },
                                    ],
                                    staticClass: "my-nft__info-video",
                                  },
                                  [
                                    _c("BaseIcon", {
                                      staticClass: "ic_play",
                                      attrs: { name: "ic_play" },
                                    }),
                                    _c("span", [_vm._v("Fighter video")]),
                                    _vm.GET_MY_CHARACTER.mf_attributes
                                      .is_can_tier_up
                                      ? _c("btn", {
                                          staticClass:
                                            "my-nft__info-video-tierup",
                                          attrs: {
                                            type: "icon-gradient",
                                            text: "TIER UP",
                                            svgIcon: "arrow-up",
                                          },
                                          on: { click: _vm.freeTierUp },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "my-nft__info-video" },
                                  [
                                    _c("BaseIcon", {
                                      directives: [
                                        {
                                          name: "popover",
                                          rawName: "v-popover.bottom",
                                          value: { name: "fighter-video" },
                                          expression:
                                            "{ name: 'fighter-video' }",
                                          modifiers: { bottom: true },
                                        },
                                      ],
                                      staticClass: "ic_play",
                                      attrs: { name: "ic_play" },
                                    }),
                                    _c("span", [_vm._v("Fighter video")]),
                                    _vm.GET_MY_CHARACTER.mf_attributes
                                      .is_can_tier_up
                                      ? _c("btn", {
                                          staticClass:
                                            "my-nft__info-video-tierup",
                                          attrs: {
                                            type: "icon-gradient",
                                            text: "TIER UP",
                                            svgIcon: "arrow-up",
                                          },
                                          on: { click: _vm.freeTierUp },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                            _c("VideoPopover", {
                              attrs: {
                                name: "fighter-video",
                                src: _vm.GET_MY_CHARACTER.mf_attributes.video,
                                preview:
                                  _vm.GET_MY_CHARACTER.mf_attributes.image,
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "my-nft__level" },
                              [
                                _c("character-level", {
                                  attrs: {
                                    requires:
                                      _vm.GET_MY_CHARACTER.mf_attributes
                                        .experience.requires,
                                    level:
                                      _vm.GET_MY_CHARACTER.mf_attributes.level,
                                    current:
                                      _vm.GET_MY_CHARACTER.mf_attributes
                                        .experience.current,
                                    points:
                                      _vm.GET_MY_CHARACTER.mf_attributes
                                        .attribute_points,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "my-nft__characteristics" },
                              _vm._l(
                                _vm.GET_MY_CHARACTER.mf_attributes.stats,
                                function (attribute, i) {
                                  return _c(
                                    "Characteristic",
                                    _vm._b(
                                      {
                                        key: i,
                                        attrs: {
                                          "points-available":
                                            _vm.GET_MY_CHARACTER.mf_attributes
                                              .attribute_points,
                                        },
                                        on: { statsLevelUp: _vm.statsLevelUp },
                                      },
                                      "Characteristic",
                                      attribute,
                                      false
                                    )
                                  )
                                }
                              ),
                              1
                            ),
                            _c("div", { staticClass: "my-nft__info-text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.GET_MY_CHARACTER.description) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm.onSale
                          ? _c("btn", {
                              staticClass: "my-nft-btn",
                              attrs: {
                                type: "icon-gradient",
                                text: "cancel order",
                                subText:
                                  _vm.GET_MY_CHARACTER.mf_attributes
                                    .sale_price &&
                                  `on sale for ${_vm.toLocaleFormat(
                                    _vm.GET_MY_CHARACTER.mf_attributes
                                      .sale_price
                                  )} MF`,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onCancelListingClick(
                                    _vm.GET_MY_CHARACTER.mf_attributes.token_id
                                  )
                                },
                              },
                            })
                          : _c("btn", {
                              staticClass: "my-nft-btn",
                              attrs: {
                                type: "icon-gradient",
                                text: "Sell",
                                disabled: _vm.onSale,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onSellNftClick(
                                    _vm.GET_MY_CHARACTER.mf_attributes.token_id
                                  )
                                },
                              },
                            }),
                        _vm.GET_MY_CHARACTER.mf_attributes.tier_up_info?.price
                          ? _c("btn", {
                              staticClass: "my-nft-btn my-nft-btn--tier-up",
                              attrs: {
                                type: "mint-gold",
                                text: "TIER UP FOR ? MF",
                                selected: _vm.toLocaleFormat(
                                  _vm.GET_MY_CHARACTER.mf_attributes
                                    .tier_up_info.price
                                ),
                                hoverText: `TIER UP FOR ${
                                  _vm.toLocaleFormat(
                                    _vm.GET_MY_CHARACTER.mf_attributes
                                      .tier_up_info.usd_price
                                  ) || 100
                                } $`,
                                disabled:
                                  _vm.onSale ||
                                  _vm.GET_MY_CHARACTER.mf_attributes
                                    .is_can_tier_up,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onTierUpClick(_vm.GET_MY_CHARACTER)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-nft__content-box my-nft__content-box--secondary",
                        attrs: {
                          "data-aos": "fade-left",
                          "data-aos-delay": _vm.GET_IS_MOBILE ? 0 : 600,
                        },
                      },
                      [
                        _c("div", { staticClass: "my-nft__skills" }, [
                          _c("i", { staticClass: "my-nft__skills-background" }),
                          _c("div", { staticClass: "my-nft__boosters" }, [
                            _c(
                              "h3",
                              { staticClass: "my-nft__boosters-title" },
                              [_vm._v("Your boosters")]
                            ),
                            _c("ul", { staticClass: "my-nft__boosters-list" }, [
                              _c(
                                "li",
                                { staticClass: "my-nft__boosters-list-item" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "my-nft__boosters-list-title",
                                    },
                                    [
                                      _vm._v(" Passive "),
                                      _c("BaseIcon", {
                                        directives: [
                                          {
                                            name: "popover",
                                            rawName: "v-popover.top",
                                            value: { name: "what-is-passive" },
                                            expression:
                                              "{ name: 'what-is-passive' }",
                                            modifiers: { top: true },
                                          },
                                        ],
                                        staticClass: "my-nft-question",
                                        attrs: { name: "question-circle" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "booster-wrapper" },
                                    [
                                      _c("booster", {
                                        attrs: {
                                          text:
                                            _vm.GET_MY_CHARACTER.mf_attributes
                                              .passive_booster?.name ||
                                            _vm.GET_MY_CHARACTER.mf_attributes
                                              .passive_booster?.rate,
                                          active:
                                            !!_vm.GET_MY_CHARACTER.mf_attributes
                                              .passive_booster?.rate,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "my-nft__boosters-list-separator",
                                },
                                [_vm._v("+")]
                              ),
                              _c(
                                "li",
                                { staticClass: "my-nft__boosters-list-item" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "my-nft__boosters-list-title",
                                    },
                                    [_vm._v("Purchased")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "booster-wrapper" },
                                    [
                                      _c("booster", {
                                        attrs: {
                                          text:
                                            _vm.GET_MY_CHARACTER.mf_attributes
                                              .active_booster.type || "",
                                          duration:
                                            _vm.GET_MY_CHARACTER.mf_attributes
                                              .active_booster.seconds_left,
                                          active:
                                            !!_vm.GET_MY_CHARACTER.mf_attributes
                                              .active_booster?.seconds_left,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "my-nft__boosters-description" },
                              [
                                _vm.purchasedBooster
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-nft__boosters-list my-nft__boosters-list--purchased",
                                      },
                                      _vm._l(
                                        _vm.purchasedBooster,
                                        function (booster) {
                                          return _c(
                                            "div",
                                            { key: booster.name },
                                            [
                                              _c("active-booster", {
                                                attrs: {
                                                  text: _vm.getBoosterText(
                                                    booster
                                                  ),
                                                  blockTime:
                                                    _vm.GET_MY_CHARACTER
                                                      .mf_attributes
                                                      .active_booster
                                                      ?.seconds_left || 0,
                                                  count: booster.values.length,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.activateBoosterPopup(
                                                      booster.rate
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "text-wrapper" }, [
                                  _c("p", [
                                    _vm._v(
                                      "Buy or Stake for EXP Booster! Buying a Booster is cheaper."
                                    ),
                                  ]),
                                ]),
                                _c("btn", {
                                  staticClass: "my-nft-btn",
                                  attrs: {
                                    type: "icon-gradient",
                                    text: "Add booster",
                                  },
                                  on: { click: _vm.getBoosterPopup },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("superpowers", {
                          attrs: {
                            character: _vm.GET_MY_CHARACTER.superpowers,
                          },
                        }),
                        _c("div", { staticClass: "my-nft__powers-wrapper" }, [
                          _c("h3", { staticClass: "my-nft__powers-title" }, [
                            _vm._v("Your skills"),
                          ]),
                          _c("div", { staticClass: "my-nft__powers" }, [
                            _c("div", { staticClass: "my-nft__powers-box" }, [
                              _c(
                                "div",
                                { staticClass: "my-nft__powers-label" },
                                [_vm._v("Active")]
                              ),
                              _c(
                                "div",
                                { staticClass: "my-nft__powers-items" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "my-nft__powers-item" },
                                    [
                                      _vm.GET_MY_CHARACTER.mf_attributes.skills
                                        ?.active
                                        ? [
                                            _c(
                                              "skill",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    active: "",
                                                    type: "active",
                                                  },
                                                },
                                                "skill",
                                                _vm.GET_MY_CHARACTER
                                                  .mf_attributes.skills.active,
                                                false
                                              )
                                            ),
                                          ]
                                        : [
                                            _c("skill", {
                                              directives: [
                                                {
                                                  name: "popover",
                                                  rawName: "v-popover",
                                                  value: {
                                                    name: "triangle_power-1",
                                                  },
                                                  expression:
                                                    "{ name: 'triangle_power-1' }",
                                                },
                                              ],
                                              attrs: { type: "active" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.stakeSkillPopup(
                                                    "active",
                                                    _vm.GET_MY_CHARACTER
                                                      .mf_attributes.token_id
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "my-nft__powers-box" }, [
                              _c(
                                "div",
                                { staticClass: "my-nft__powers-label" },
                                [_vm._v("Passive")]
                              ),
                              _c(
                                "div",
                                { staticClass: "my-nft__powers-items" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "my-nft__powers-item" },
                                    [
                                      _vm.GET_MY_CHARACTER.mf_attributes.skills
                                        ?.passive1
                                        ? [
                                            _c(
                                              "skill",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    active: "",
                                                    type: "passive",
                                                  },
                                                },
                                                "skill",
                                                _vm.GET_MY_CHARACTER
                                                  .mf_attributes.skills
                                                  .passive1,
                                                false
                                              )
                                            ),
                                          ]
                                        : [
                                            _c("skill", {
                                              directives: [
                                                {
                                                  name: "popover",
                                                  rawName: "v-popover",
                                                  value: {
                                                    name: "triangle_power-2",
                                                  },
                                                  expression:
                                                    "{ name: 'triangle_power-2' }",
                                                },
                                              ],
                                              attrs: { type: "passive" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.stakeSkillPopup(
                                                    "passive",
                                                    _vm.GET_MY_CHARACTER
                                                      .mf_attributes.token_id
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "my-nft__powers-item" },
                                    [
                                      _vm.GET_MY_CHARACTER.mf_attributes.skills
                                        ?.passive2
                                        ? [
                                            _c(
                                              "skill",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    active: "",
                                                    type: "passive",
                                                  },
                                                },
                                                "skill",
                                                _vm.GET_MY_CHARACTER
                                                  .mf_attributes.skills
                                                  .passive2,
                                                false
                                              )
                                            ),
                                          ]
                                        : [
                                            _c("skill", {
                                              directives: [
                                                {
                                                  name: "popover",
                                                  rawName: "v-popover",
                                                  value: {
                                                    name: "triangle_power-2",
                                                  },
                                                  expression:
                                                    "{ name: 'triangle_power-2' }",
                                                },
                                              ],
                                              attrs: { type: "passive" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.stakeSkillPopup(
                                                    "passive",
                                                    _vm.GET_MY_CHARACTER
                                                      .mf_attributes.token_id
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          !_vm.haveAnySklills
                            ? _c("div", { staticClass: "my-nft__powers-ps" }, [
                                _c("span", [
                                  _vm._v(
                                    "You don't have any skills purchased yet."
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.GET_IS_MOBILE
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "popover",
                                    rawName: "v-popover.bottom",
                                    value: { name: "super-kick-video" },
                                    expression: "{ name: 'super-kick-video' }",
                                    modifiers: { bottom: true },
                                  },
                                ],
                                staticClass: "my-nft__powers-video",
                              },
                              [
                                _c("BaseIcon", {
                                  staticClass:
                                    "minting-nft__powers-play ic_play",
                                  attrs: { name: "ic_play" },
                                }),
                                _c("span", [_vm._v("Watch super kick")]),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "my-nft__powers-video" },
                              [
                                _c("BaseIcon", {
                                  directives: [
                                    {
                                      name: "popover",
                                      rawName: "v-popover.bottom",
                                      value: { name: "super-kick-video" },
                                      expression:
                                        "{ name: 'super-kick-video' }",
                                      modifiers: { bottom: true },
                                    },
                                  ],
                                  staticClass:
                                    "minting-nft__powers-play ic_play",
                                  attrs: { name: "ic_play" },
                                }),
                                _c("span", [_vm._v("Watch super kick")]),
                              ],
                              1
                            ),
                        _c("VideoPopover", {
                          attrs: {
                            name: "super-kick-video",
                            src: _vm.GET_MY_CHARACTER.mf_attributes
                              .super_kick_video,
                            preview: _vm.GET_MY_CHARACTER.mf_attributes.image,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "my-nft__colors" },
                          _vm._l(
                            _vm.GET_MY_CHARACTER.mf_attributes.style,
                            function (style, key) {
                              return _c("entity-color", {
                                key: key,
                                staticClass: "my-nft__colors-item",
                                attrs: {
                                  title: key,
                                  text: style.name,
                                  color: style.value,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
          _c("activate-booster-popup", {
            ref: "activateBoosterPopup",
            on: { activate: _vm.activateBooster },
          }),
          _c("get-booster-popup", {
            ref: "getBoosterPopup",
            on: {
              stakeBooster: _vm.stakeBooster,
              buyBoosterHandler: _vm.buyBoosterHandler,
            },
          }),
          _c("stake-skill-popup", {
            ref: "stakeSkillPopup",
            on: { stakeSkillProcess: _vm.stakeSkillProcess },
          }),
          _c("tier-up-success-popup", { ref: "tierUpSuccessPopup" }),
          _c("stake-success-popup", { ref: "stakeSuccessPopup" }),
          _c("transaction-success-popup", { ref: "transactionSuccessPopup" }),
          _c("sell-price-popup", { ref: "sellPricePopup" }),
          _c("request-success-popup", { ref: "requestSuccessPopup" }),
          _c("approve-popup", {
            ref: "approvePopup",
            attrs: {
              "action-btn-text": "TIER UP",
              successMsg:
                "You have approved a sufficient number of MF tokens. Now you can tier up the character.",
            },
            on: { "action-btn-click": _vm.tierUp },
          }),
          _vm.isProcessing
            ? _c("preloader", { attrs: { translucent: "", black: "" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }