<template>
    <div
        @click="$emit('click')"
        :class="['booster', { active }]"
    >
        <span
            v-if="active"
            v-popover.bottom="{name: text}"
            class="booster-rate"
        >
            {{text}}
        </span>

        <span
            v-else
            class="booster__text"
        >{{emptyText}}</span>


        <portal v-if="duration" to="main-popup">
            <popover
                :name="text"
                :width="290"
                event="hover"
                class="booster__popover"
            >
                <div class="passive">
                    <div class="booster__popover-content">
                        <h2 class="booster__popover-title">Booster {{text}}</h2>

                        <div class="booster__popover-text">
                            remaining time:
                        </div>

                        <div class="booster__popover-timer">
                            <div class="booster__popover-timer">
                                <staking-timer :time="duration"/>
                            </div>
                        </div>
                    </div>
                </div>
            </popover>
        </portal>
    </div>
</template>

<script>
import StakingTimer from './staking-timer'

export default {
    name: 'Booster',
    components: {
        StakingTimer,
    },
    props: {
        active: { type: Boolean, default: false },
        emptyText: { type: String, default: '+' },
        text: { type: String, default: '' },
        duration: {type: Number, default: 0 },
    },
}
</script>

<style lang="scss" scoped>

.booster {
    position: relative;
    z-index: 0;
    width: 48px;
    height: 44px;
    padding: 3px;
    background-color: #919EB7;
    display: flex;
    justify-content: center;
    align-items: center;

    &-rate {
        color: black;
        font: 500 20px "Tektur";
        cursor: pointer;
    }

    &.active {
        background: linear-gradient(to left top, #FFCC13, #FF9509);

        .booster-rate {
            cursor: default;
            padding: 10px;
        }

        &:before,
        &:after {
            display: none;
        }
    }

    &__popover {
        background-color: #CBD3E1;
        padding: 12px 18px;
        transform: skewX(-4deg);
        border-radius: 0;

        &-title {
            text-transform: uppercase;
            font: 800 24px Raleway;
            margin-bottom: 8px;
        }
        &-text {
            padding: 8px 0;
            font: 500 14px "Tektur";

            &--description {
                padding: 2px 0;
            }
        }
        &-timer {
            padding: 5px;
            background-color: white;
            transform: skewX(4deg);
            text-transform: uppercase;
        }
    }
}

.booster__text {
    width: 100%;
    height: 100%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6B7B98;
    color: #919EB7;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;

    &--active {

    }
}

</style>
