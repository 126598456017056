<template>
    <popup v-model="popup" @input="close" content-class="booster-popup">
        <div class="booster-popup__content">
            <div class="booster-popup__title">Activate Booster</div>

            <div class="booster-popup__text">
                Here are all your boosters and their time until the end.
                Once activated, you won't be able to remove it manually.
                You can apply a new one only after the completion of the previous one.
            </div>

            <div class="booster-popup__radios">
                <Loader v-if="isLoading" xs/>
                <div
                    v-else
                    v-for="booster in filteredBoosters"
                    :key="booster.id"
                    class="booster-popup__radios-item"
                >
                    <span>On {{convertToTime(booster.expired_at)}}</span>

                    <radio
                        :val="String(booster.id)"
                        v-model="selectedBooster"
                    />
                </div>
            </div>
        </div>

        <div class="booster-popup__buttons">
            <btn
                class="booster-popup__buttons-item"
                type="bg-white"
                text="Cancel"
                @click="close"
            />

            <btn
                class="booster-popup__buttons-item"
                type="icon-gradient"
                text="Apply"
                @click="activate"
            />
        </div>
    </popup>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Loader from '@/components/common/loader.vue'

export default {
    name: "ActivateBoostersPopup",
    components: {
        Loader,
    },
    computed: {
        ...mapGetters('generalData', ['myBoosters']),

        filteredBoosters() {
            return this.myBoosters.filter(({booster}) => booster.rate === this.boosterType);
        },
    },
    data() {
        return {
            popup: false,
            boosterType: null,
            selectedBooster: null,
            isLoading: false,
        }
    },
    methods: {
        ...mapActions('generalData', ['getMyBoosters']),

        async open(boosterType){
            this.boosterType = boosterType;
            this.popup = true;
            this.isLoading = true;

            await this.getMyBoosters();

            this.isLoading = false;

            await this.$nextTick();

            if (this.filteredBoosters.length) {
                this.selectedBooster = String(this.filteredBoosters[0].id);
            }
        },
        close() {
            this.$emit('close');
            this.popup = false;
            this.boosterType = null;
            this.selectedBooster = null;
        },
        activate() {
            this.$emit('activate', this.selectedBooster);
            this.close();
        },
        convertToTime(date) {
            const seconds = this.getSecondsDiff(new Date(), new Date(date));

            const days = Math.floor(parseInt(seconds, 10) / (3600*24));

            if (days > 0) {
                return `${days} days`;
            }

            const hours = Math.floor(parseInt(seconds, 10) / 3600);

            if (hours > 0) {
                return `${hours} hours`;
            }

            const minutes = Math.floor(parseInt(seconds, 10) / 60);

            if (minutes > 0) {
                return `${minutes} minutes`;
            }

            return `${seconds} seconds`;
        },

        getSecondsDiff(startDate, endDate) {
            const msInSecond = 1000;

            return Math.round(
                Math.abs(endDate - startDate) / msInSecond
            );
        }
    }
}
</script>

<style lang="scss">

.booster-popup {
    width: 100%;
    max-width: 645px;
    flex-direction: column;
    background-color: #C6CCD7;
    padding: 2em 3em;
    border-radius: 15px;

    .popup__close-btn {
        background-color: transparent;

        .close-btn__element {
            background-color: black;
            height: 6px;
            width: 25px;
        }
    }
}

.booster-popup__title {
    text-align: center;
    text-transform: uppercase;
    font: 800 48px Raleway;
}

.booster-popup__text {
    margin: 1.5em 0;
    text-align: center;
    font: 400 16px Tektur;
}

.booster-popup__content {
    padding: 20px 20px 0 20px;
    margin-bottom: 20px;
}

.booster-popup__radios {
    margin-top: 15px;
    background-color: #3B3D41;
    color: white;
    padding: 40px 72px;
}

.booster-popup__radios-item {
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font: 600 18px Tektur;

    &:last-child {
        margin-bottom: 0;
    }
}

.booster-popup__buttons {
    display: flex;
    gap: 2em;
    padding: 10px 20px;
}

.booster-popup__buttons-item {
    flex-grow: 1;
    min-width: auto;
}

@media screen and (max-width: $bp_mb) {
    .booster-popup__title {
        font-size: 24px;
    }

    .booster-popup__text {
        font-size: 12px;
    }

    .booster-popup__buttons {
        gap: 10px;
        flex-direction: column;
        padding: 0;

        &-item {
            width: 100%;
        }
    }

    .booster-popup__radios {
        padding: 20px 35px;
    }

    .booster-popup__content {
        padding: 0;
    }
}
</style>
