// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".request-success-popup {\n  max-width: 570px !important;\n  padding-bottom: 15px !important;\n}\n.request-success-popup-chackmark {\n  position: absolute;\n  top: 1em;\n  left: calc(50% - 23px);\n  width: 36px;\n}\n.request-success-popup__content {\n  text-transform: uppercase;\n  text-align: center;\n}\n.request-success-popup__title {\n  font: 800 21px Raleway;\n  margin-bottom: 10px;\n}\n.request-success-popup__desc {\n  font-size: 14px;\n}\n.request-success-popup__buttons {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n}\n.request-success-popup__buttons-item {\n  margin-right: 15px;\n}\n.request-success-popup__buttons-item:last-child {\n  margin-right: 0;\n}\n.hash {\n  font-size: 12px;\n  word-break: break-all;\n  color: #1a52ee;\n  cursor: pointer;\n}\n.hash__img {\n  height: 1em;\n  margin-left: 5px;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
