@@ -1,335 +0,0 @@
<template>
  <ContentCharacter class="market-place">
    <template slot="content">
      <Loader v-if="isLoadingData"/>

      <div v-else class="my-nft">
        <market-place-header :urls="getPathUrls" />

        <div class="my-nft__content">
          <div
              data-aos="fade-right"
              data-aos-delay="300"
              class="my-nft__content-box my-nft__content-box--card"
          >
            <card-wrapper
              @click="$emit('click')"
              :image="GET_MY_CHARACTER.mf_attributes.image"
              :web-images="GET_MY_CHARACTER.mf_attributes.web_images"
              :type="GET_MY_CHARACTER.mf_attributes.type"
              :tier="GET_MY_CHARACTER.mf_attributes.tier"
              :statistic="statistic"
              :isBackground="false"
              image-center
              fullSize
              class="my-nft__avatar"
            />
          </div>
          <div
              data-aos="fade-right"
              data-aos-delay="300"
              class="my-nft__content-box my-nft__content-box--main"
          >
            <div class="my-nft__info">
                    <h2 class="my-nft__info-title">{{ GET_MY_CHARACTER.name }}</h2>

                    <div
                        v-if="GET_IS_MOBILE"
                        v-popover.bottom="{ name: 'fighter-video' }"
                        class="my-nft__info-video"
                    >
                        <BaseIcon class="ic_play" name="ic_play"/>
                        <span>Fighter video</span>

                        <btn
                            v-if="GET_MY_CHARACTER.mf_attributes.is_can_tier_up"
                            class="my-nft__info-video-tierup"
                            type="icon-gradient"
                            text="TIER UP"
                            svgIcon="arrow-up"
                            @click="freeTierUp"
                        />
                    </div>
                    <div
                        v-else
                        class="my-nft__info-video"
                    >
                        <BaseIcon v-popover.bottom="{ name: 'fighter-video' }" class="ic_play" name="ic_play"/>
                        <span>Fighter video</span>

                        <btn
                            v-if="GET_MY_CHARACTER.mf_attributes.is_can_tier_up"
                            class="my-nft__info-video-tierup"
                            type="icon-gradient"
                            text="TIER UP"
                            svgIcon="arrow-up"
                            @click="freeTierUp"
                        />
                    </div>

                    <VideoPopover
                        name="fighter-video"
                        :src="GET_MY_CHARACTER.mf_attributes.video"
                        :preview="GET_MY_CHARACTER.mf_attributes.image"
                    />

                    <div class="my-nft__level">
                        <character-level
                            :requires="GET_MY_CHARACTER.mf_attributes.experience.requires"
                            :level="GET_MY_CHARACTER.mf_attributes.level"
                            :current="GET_MY_CHARACTER.mf_attributes.experience.current"
                            :points="GET_MY_CHARACTER.mf_attributes.attribute_points"
                        />
                    </div>

                    <div class="my-nft__characteristics">
                        <Characteristic
                            v-for="(attribute, i) in GET_MY_CHARACTER.mf_attributes.stats"
                            :key="i"
                            v-bind="attribute"
                            :points-available="GET_MY_CHARACTER.mf_attributes.attribute_points"
                            @statsLevelUp="statsLevelUp"
                        />
                    </div>

                    <div class="my-nft__info-text">
                      {{ GET_MY_CHARACTER.description }}
                    </div>
            </div>

            <btn
                v-if="onSale"
                class="my-nft-btn"
                type="icon-gradient"
                text="cancel order"
                :subText="GET_MY_CHARACTER.mf_attributes.sale_price && `on sale for ${ toLocaleFormat(GET_MY_CHARACTER.mf_attributes.sale_price) } MF`"
                @click="onCancelListingClick(GET_MY_CHARACTER.mf_attributes.token_id)"
            />

            <btn
                v-else
                class="my-nft-btn"
                type="icon-gradient"
                text="Sell"
                @click="onSellNftClick(GET_MY_CHARACTER.mf_attributes.token_id)"
                :disabled="onSale"
            />

            <btn
                v-if="GET_MY_CHARACTER.mf_attributes.tier_up_info?.price"
                class="my-nft-btn my-nft-btn--tier-up"
                type="mint-gold"
                text="TIER UP FOR ? MF"
                :selected="toLocaleFormat(GET_MY_CHARACTER.mf_attributes.tier_up_info.price)"
                :hoverText="`TIER UP FOR ${toLocaleFormat(GET_MY_CHARACTER.mf_attributes.tier_up_info.usd_price) || 100} $`"
                @click="onTierUpClick(GET_MY_CHARACTER)"
                :disabled="onSale || GET_MY_CHARACTER.mf_attributes.is_can_tier_up"
            />
          </div>

          <div
              data-aos="fade-left"
              :data-aos-delay="GET_IS_MOBILE ? 0 : 600"
              class="my-nft__content-box my-nft__content-box--secondary"
          >
              <div class="my-nft__skills">
                  <i class="my-nft__skills-background"/>
                  <div class="my-nft__boosters">
                      <h3 class="my-nft__boosters-title">Your boosters</h3>
                      <ul class="my-nft__boosters-list">
                          <li
                              class="my-nft__boosters-list-item"
                          >
                              <span class="my-nft__boosters-list-title">
                                  Passive
                                  <BaseIcon
                                      v-popover.top="{ name: 'what-is-passive' }"
                                      class="my-nft-question"
                                      name="question-circle"
                                  />
                              </span>
                              <div class="booster-wrapper">
                                  <booster
                                      :text="GET_MY_CHARACTER.mf_attributes.passive_booster?.name
                                      || GET_MY_CHARACTER.mf_attributes.passive_booster?.rate"
                                      :active="!!GET_MY_CHARACTER.mf_attributes.passive_booster?.rate"
                                  />
                              </div>
                          </li>
                          <li class="my-nft__boosters-list-separator">+</li>
                          <li
                              class="my-nft__boosters-list-item"
                          >
                              <span class="my-nft__boosters-list-title">Purchased</span>
                              <div class="booster-wrapper">
                                  <booster
                                      :text="GET_MY_CHARACTER.mf_attributes.active_booster.type || ''"
                                      :duration="GET_MY_CHARACTER.mf_attributes.active_booster.seconds_left"
                                      :active="!!GET_MY_CHARACTER.mf_attributes.active_booster?.seconds_left"
                                  />
                              </div>
                          </li>
                      </ul>

                      <div class="my-nft__boosters-description">
                          <div
                              v-if="purchasedBooster"
                              class="my-nft__boosters-list my-nft__boosters-list--purchased"
                          >
                              <div
                                  v-for="booster in purchasedBooster"
                                  :key="booster.name"
                              >
                                  <active-booster
                                      :text="getBoosterText(booster)"
                                      :blockTime="GET_MY_CHARACTER.mf_attributes.active_booster?.seconds_left || 0"
                                      :count="booster.values.length"
                                      @click="activateBoosterPopup(booster.rate)"
                                  />
                              </div>
                          </div>

                          <div class="text-wrapper">
                              <p>Buy or Stake for EXP Booster! Buying a Booster is cheaper.</p>
                          </div>

                          <btn
                              class="my-nft-btn"
                              type="icon-gradient"
                              text="Add booster"
                              @click="getBoosterPopup"
                          />
                      </div>
                  </div>
              </div>

              <superpowers :character="GET_MY_CHARACTER.superpowers" />

              <div class="my-nft__powers-wrapper">
                  <h3 class="my-nft__powers-title">Your skills</h3>
                  <div class="my-nft__powers">
                      <div class="my-nft__powers-box">
                          <div class="my-nft__powers-label">Active</div>
                          <div class="my-nft__powers-items">
                              <div class="my-nft__powers-item">
                                  <template v-if="GET_MY_CHARACTER.mf_attributes.skills?.active">
                                      <skill
                                          v-bind="GET_MY_CHARACTER.mf_attributes.skills.active"
                                          active
                                          type="active"
                                      />
                                  </template>

                                  <template v-else>
                                      <skill
                                          v-popover="{ name: 'triangle_power-1' }"
                                          @click="stakeSkillPopup('active', GET_MY_CHARACTER.mf_attributes.token_id)"
                                          type="active"
                                      />
                                  </template>
                              </div>
                          </div>
                      </div>

                      <div class="my-nft__powers-box">
                          <div class="my-nft__powers-label">Passive</div>

                          <div class="my-nft__powers-items">
                              <div class="my-nft__powers-item">
                                  <template v-if="GET_MY_CHARACTER.mf_attributes.skills?.passive1">
                                      <skill
                                          v-bind="GET_MY_CHARACTER.mf_attributes.skills.passive1"
                                          active
                                          type="passive"
                                      />
                                  </template>

                                  <template v-else>
                                      <skill
                                          v-popover="{ name: 'triangle_power-2' }"
                                          @click="stakeSkillPopup('passive', GET_MY_CHARACTER.mf_attributes.token_id)"
                                          type="passive"
                                      />
                                  </template>
                              </div>
                              <div class="my-nft__powers-item">
                                  <template v-if="GET_MY_CHARACTER.mf_attributes.skills?.passive2">
                                      <skill
                                          v-bind="GET_MY_CHARACTER.mf_attributes.skills.passive2"
                                          active
                                          type="passive"
                                      />
                                  </template>

                                  <template v-else>
                                      <skill
                                          v-popover="{ name: 'triangle_power-2' }"
                                          @click="stakeSkillPopup('passive', GET_MY_CHARACTER.mf_attributes.token_id)"
                                          type="passive"
                                      />
                                  </template>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div
                      v-if="!haveAnySklills"
                      class="my-nft__powers-ps"
                  >
                      <span>You don't have any skills purchased yet.</span>
                  </div>
              </div>

              <div
                  v-if="GET_IS_MOBILE"
                  v-popover.bottom="{ name: 'super-kick-video' }"
                  class="my-nft__powers-video"
              >
                  <BaseIcon class="minting-nft__powers-play ic_play" name="ic_play" />
                  <span>Watch super kick</span>
              </div>
              <div
                  v-else
                  class="my-nft__powers-video"
              >
                  <BaseIcon v-popover.bottom="{ name: 'super-kick-video' }" class="minting-nft__powers-play ic_play" name="ic_play" />
                  <span>Watch super kick</span>
              </div>

              <VideoPopover
                  name="super-kick-video"
                  :src="GET_MY_CHARACTER.mf_attributes.super_kick_video"
                  :preview="GET_MY_CHARACTER.mf_attributes.image"
              />

              <div class="my-nft__colors">
                  <entity-color
                      v-for="(style, key) in GET_MY_CHARACTER.mf_attributes.style"
                      :key="key"
                      class="my-nft__colors-item"
                      :title="key"
                      :text="style.name"
                      :color="style.value"
                  />
              </div>
          </div>
        </div>

      </div>

      <activate-booster-popup
          @activate="activateBooster"
          ref="activateBoosterPopup"
      />
      <get-booster-popup
          @stakeBooster="stakeBooster"
          @buyBoosterHandler="buyBoosterHandler"
          ref="getBoosterPopup"
      />
      <stake-skill-popup ref="stakeSkillPopup" @stakeSkillProcess="stakeSkillProcess"/>
      <tier-up-success-popup ref="tierUpSuccessPopup"/>
      <stake-success-popup ref="stakeSuccessPopup"/>
      <transaction-success-popup ref="transactionSuccessPopup"/>
      <sell-price-popup ref="sellPricePopup"/>
      <request-success-popup ref="requestSuccessPopup"/>
      <approve-popup
          ref="approvePopup"
          action-btn-text="TIER UP"
          successMsg="You have approved a sufficient number of MF tokens. Now you can tier up the character."
          @action-btn-click="tierUp"
      />
      <preloader translucent black v-if="isProcessing" />
    </template>
  </ContentCharacter>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ContentCharacter from '@/components/common/content-character.vue'
import Characteristic from '@/components/common/characteristic.vue'
import Booster from '@/components/common/booster.vue'
import GetBoosterPopup from '@/components/popups/get-boosters-popup.vue'
import ActivateBoosterPopup from '@/components/popups/activate-boosters-popup.vue'
import RequestSuccessPopup from '@/components/popups/request-success-popup.vue'
import ActiveBooster from '@/components/common/active-booster.vue'
import Skill from '@/components/common/skill.vue'
import CardWrapper from '@/components/common/card-wrapper.vue'
import VideoPopover from '@/components/popovers/video-popover.vue'
import EntityColor from '@/components/common/entity-color.vue'
import MarketPlaceHeader from '@/components/market-place/header.vue'
import Superpowers from '@/components/popovers/superpowers.vue'
import CharacterLevel from '@/components/common/character-level.vue'
import Loader from '@/components/common/loader.vue'
import Tiers from "@/constants/Tiers.js";
import stakeMixin from "@/mixins/stakeMixin";
import marketplaceMixin from "@/mixins/marketplaceMixin";
import ApiBoosters from "@/service/ApiBoosters";
import ApiCharacters from "@/service/ApiCharacters";
import {isStringsEquals} from "@/utils/helpers";

export default {
    name: 'MyNftSingle',
    mixins: [stakeMixin, marketplaceMixin],
    props: {
        id: { type: [String, Number], required: true },
    },
    components: {
        Superpowers,
        ContentCharacter,
        Characteristic,
        Booster,
        GetBoosterPopup,
        ActivateBoosterPopup,
        ActiveBooster,
        Skill,
        CardWrapper,
        EntityColor,
        MarketPlaceHeader,
        CharacterLevel,
        Loader,
        RequestSuccessPopup,
        VideoPopover,
    },
    watch: {
        account() {
            this.loadData();
        }
    },
    data() {
        return {
            isLoadingData: true,
        }
    },
    computed: {
        ...mapGetters([
          'account',
          'GET_IS_MOBILE',
          'GET_MY_CHARACTER',
          'GET_IS_DEVELOPMENT'
        ]),

        getPathUrls() {
            return [
                { label: 'Marketplace', name: 'Market' },
                { label: 'MY NFT', name: 'MyNfts' },
                { label: this.GET_MY_CHARACTER.name },
            ]
        },
        statistic() {
            return [
                { title: 'Tier', value: Tiers[this.GET_MY_CHARACTER.mf_attributes.tier] },
                { title: 'Win', value: this.GET_MY_CHARACTER.mf_attributes.wins },
                { title: 'Lose', value: this.GET_MY_CHARACTER.mf_attributes.losses },
            ]
        },
        onSale() {
            return this.GET_MY_CHARACTER.mf_attributes.status === 1;
        },
        haveAnySklills() {
            const {skills} = this.GET_MY_CHARACTER.mf_attributes;

            return skills.passive1 || skills.passive2 || skills.active;
        },
        purchasedBooster() {
            const availableBoosters = [];

            Object.entries(this.GET_MY_CHARACTER.mf_attributes.boosters).forEach(([key, value]) => {
                availableBoosters.push({
                    name: key,
                    rate: value[0].rate,
                    values: value,
                });
            });

            return availableBoosters.length ? availableBoosters : null;
        },
        isMyCharacter() {
            return isStringsEquals(this.GET_MY_CHARACTER.mf_attributes.owner, this.account);
        },
    },

    mounted() {
        this.loadData();
    },

    methods: {
        ...mapActions([
            'REQUEST_MY_CHARACTER',
        ]),
        getBoosterText(booster) {
            const result = this.boosters.find((el) => el.rate === booster.rate);

            return result?.name || booster.rate;
        },
        async loadData() {
            await this.REQUEST_MY_CHARACTER(this.id)

            if (!this.GET_MY_CHARACTER || !this.isMyCharacter) {
                this.$router.push({name: 'MyNfts'}).catch(()=>{});
            }

            this.isLoadingData = false;
        },
        getBoosterPopup() {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            this.$refs.getBoosterPopup?.open();
        },
        activateBoosterPopup(booster) {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            this.$refs.activateBoosterPopup?.open(booster);
        },
        async activateBooster(boosterId) {
            this.isProcessing = true;

            try {
                await ApiBoosters.activateBooster(boosterId, this.GET_MY_CHARACTER.mf_attributes.token_id);
                await this.REQUEST_MY_CHARACTER(this.id);

                this.$refs.requestSuccessPopup?.open('Booster successfully activated');
            } finally {
                this.isProcessing = false;
            }
        },
        async statsLevelUp(stat) {
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            this.isProcessing = true;

            try {
                await ApiCharacters.statsLevelUp(this.GET_MY_CHARACTER.mf_attributes.token_id, stat);
                await this.REQUEST_MY_CHARACTER(this.id);
            } finally {
                this.isProcessing = false;
            }
        },
        async freeTierUp() {
            console.log('Tier up')
            if (!this.isLoggedIn) {
                this.$eventBus.$emit('errorPopup', '', 'Please connect to the Metamask through the Metafighter website');

                return;
            }

            this.isProcessing = true;

            try {
                await ApiCharacters.freeTierUp(this.GET_MY_CHARACTER.mf_attributes.token_id);
                await this.REQUEST_MY_CHARACTER(this.id);
            } finally {
                this.isProcessing = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.my-nft {
    color: white;
}
.my-nft__content {
    padding: 2em 0;
    margin: 0;
    display: grid;
    grid-template-columns: 385px 500px 438px;
    justify-content: space-between;
    grid-gap: 4em;
}

.my-nft__content-box {
    &--card {
        padding: 0 1em;

        @media screen and (max-width: 1250px) {
            padding: 0;
        }
    }
}

.my-nft__avatar {
    width: auto;
}

.my-nft__skills {
    margin-top: 2em;
    padding: 1em 0;
    position: relative;

    &-background {
        position: absolute;
        top: -10%;
        bottom: -10%;
        left: -10%;
        right: -10000%;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 1px 4px rgba(0, 55, 96, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.25), inset 3px 4px 4px rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        transform: skewX(-4deg);
        pointer-events: none;
    }
}

.my-nft-btn {
    width: 210px;
    margin-top: 1.5em;

    @media screen and (max-width: 1250px) {
        margin-left: calc(50% - 100px);
    }

    &--tier-up {
        margin-left: 2em;
        color: #ffb909;
        width: 220px;

        &:hover {
            color: black;
        }

        @media screen and (max-width: 1250px) {
            margin-left: calc(50% - 100px);
        }
    }
}

.my-nft__colors {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1em;
    column-gap: 2em;
    margin-top: 71px;
    justify-content: space-between;

    @media screen and (max-width: $bp_mb_xs) {
        justify-content: center;
    }
}
.my-nft__colors-item {
    margin-bottom: 5px;
    width: 200px;

    &:last-child {
        margin-bottom: 0;
    }
}

.my-nft__info-title {
    font: 800 56px Raleway;
    text-transform: uppercase;
    font-style: italic;
}

.my-nft__info-video {
	font: 700 16px Raleway;
	color: #BFBFBF;
    margin-top: 1em;
    display: flex;
    align-items: center;
    gap: 1em;

    &-tierup {
        margin-left: auto;
        padding: 5px 40px;
        width: 165px;
        min-width: 165px;
        min-height: 32px;

        @media screen and (max-width: $bp_mb) {
            padding: 5px 20px;
            width: 125px;
            min-width: 125px;
        }
    }
}

.my-nft__info-text {
    white-space: pre-line;
    margin-top: 15px;
    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.01em;
}

.my-nft__boosters {
    text-transform: uppercase;
}
.my-nft__boosters-title {
    font: 800 24px Raleway;
}
.my-nft__boosters-list {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 1em;

    &-title {
        font-weight: 500;
        font-size: 13px;
        text-transform: capitalize;
    }

    &-separator {
        font-size: 1.7em;
        font-weight: 500;
        margin: 18px 10px 0 0;
    }
}

.booster-wrapper {
    margin-top: 6px;
    display: flex;
    gap: 2em;
}

.my-nft__boosters-description {
    display: flex;
    margin-top: 2em;
    gap: 2em;
    flex-direction: column;

    .text-wrapper {
        padding-top: 5px;
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0.01em;
        text-transform: initial;
    }
    .my-nft-btn {
        margin-top: 0;
    }
}

.my-nft__btns-boosters {
    display: flex;
    gap: 1.5em;
    flex-wrap: wrap;
    margin-top: 32px;
    align-items: center;
    text-transform: uppercase;
}
.my-nft__btns-boosters-item {
    display: flex;
    align-items: center;

    &:last-child {
        margin-right: 0;
    }
}
.my-nft__btns-boosters-text {
    font-size: 24px;
    line-height: 2em;
    min-width: 2em;
    font-weight: 500;
    text-align: center;
}
.my-nft__btns-boosters-btn {
    margin: 0;
}


.my-nft__level {
    text-transform: uppercase;
    font-weight: 500;
}

.my-nft__powers-wrapper {
    margin-top: 75px;
}

.my-nft__powers {
    margin-right: 1.5em;
    display: flex;
    gap: 3em;

    &-item {
        max-width: 64px;
        word-break: break-all;
    }

    &-title {
        font: 800 24px Raleway;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    &-label {
        margin-bottom: 0.1em;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
    }

	&-video {
		font: 700 20px Raleway;
		font-style: italic;
		color: #BFBFBF;
		margin-top: 1em;
		display: flex;
		align-items: center;
		gap: 1em;
	}

    &-items {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5em;
    }

    &-ps {
        color: #BFBFBF;
        font-size: 16px;
        line-height: 1.5em;
        margin-top: 1em;
    }

    @media screen and (max-width: $bp_mb) {
        margin-right: 0;
        margin-bottom: 75px;
    }
}

.my-nft__characteristics {
    width: 100%;
    padding-top: 1.5em;
}

.my-nft__video {
    width: 100%;
    max-width: 457px;
    margin-top: 30px;
}

@media screen and (max-width: 1250px) {
    .my-nft__content-box--card {
        grid-area: 1 / 1 / 2 / 2;
    }
    .my-nft__content-box--main {
        grid-area: 1 / 2 / 2 / 3;
    }
    .my-nft__content-box--secondary {
        grid-area: 2 / 1 / 3 / 3;
    }
    .my-nft__content {
        grid-template-columns: repeat(2, 1fr);
    }

    .my-nft__info {
        padding: 0;
    }

    .my-nft__video {
        margin-top: 65px;
    }

    .my-nft__powers,
    .my-nft__powers-video,
    .my-nft__boosters,
    .my-nft__boosters-list,
    .my-nft__btns-boosters,
    .my-nft__powers-wrapper {
        justify-content: center;
        text-align: center;
    }

    .my-nft__boosters-description {
        flex-direction: column;
        gap: 2em;
    }
    .my-nft__skills-background {
        box-shadow: none;
    }
}
@media screen and (max-width: 760px) {
    .my-nft__content {
        grid-template-columns: 1fr;
    }

    .my-nft__content-box {
        grid-area: auto;
    }

    .my-nft__powers-title {
        text-align: center;
    }
    .my-nft__info-title {
        text-align: center;
        margin-top: 0.5em;
    }
    .my-nft__info-video {
        justify-content: center;
    }
}

@media screen and (max-width: $bp_mb) {
    .my-nft__top {
        flex-direction: column;
    }

    .my-nft__top-actions {
        margin-top: 20px;
    }

    .my-nft__boosters {
        text-align: center;

        &-list {
            justify-content: center;
        }
    }

    .my-nft__btns-boosters {
        flex-direction: column;

        &-text {
            margin-right: 0;
            margin-bottom: 8px;
        }

        &-item {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .my-nft__content {
        border: none;
        grid-gap: 2em;
    }

    .my-nft__powers {
        flex-direction: column;
        align-items: center;
    }

    .my-nft__powers-label {
        //margin-bottom: 10px;
        text-align: center;
    }

    .my-nft__video {
        width: auto;
        margin: 20px -15px 0;
    }

    .my-nft__boosters-list--purchased {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
}

</style>
